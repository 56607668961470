import { ApiClient } from "../../apiClient";

export function postBookmark(
  article_id: string,
  signal?: AbortSignal
): Promise<void> {
  const settingsClient = new ApiClient("POST", "settings");

  return settingsClient.send(
    `/bookmarks/${article_id}`,
    {
      mode: "cors",
      credentials: "include",
      signal
    },
    "empty"
  );
}
