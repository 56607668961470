import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef
} from "react";
import VisibilitySensor from "react-visibility-sensor";

import type { ReactNode, CSSProperties } from "react";
import type { AdRuleType } from "../../client/";
import type { AdPositions, AstAdObj } from "@omni/ads";

import { configureAndLoadAdPlacement } from "./utils/ads/xandr";
import { ArticleContext } from "../../contexts/ArticleContext";
import { useEnhancedRouter } from "../../hooks/useEnhancedRouter";
import { useCookiePermission } from "../../hooks/cookiePermission/useCookiePermission";
import { Takeover } from "./components/Takeover";
import {
  forceShow,
  frequencyControlFilter,
  getAdRule,
  getApnTag,
  getGeoTrackingPermission,
  getOffset,
  getPreBanneClassNames,
  getTopicName
} from "./utils/ads/functions";
import { getConfig } from "../../config";
import { CogwheelButton } from "../Modal/buttons/CoghwheelButton";
import { isConfiguredAndLoaded } from "./utils/ads/constants";
import { useGlobalStore } from "../../containers/GlobalStateContainer/store";
import { useConsentToAll } from "../../hooks/cookiePermission/useConsentToAll";

export const Ad = ({
  id,
  className,
  children,
  onFailure,
  onLoaded,
  onAvailable,
  customStyle = {},
  skipPre = false,
  type = "banner",
  placement
}: {
  id: string;
  className: string;
  customStyle?: CSSProperties;
  onFailure?: (e?: AstAdObj | null) => void;
  onLoaded?: (adResponse: AstAdObj, adRule: AdRuleType) => void;
  onAvailable?: (e: AstAdObj, adRule: AdRuleType) => void;
  children?: ReactNode;
  skipPre?: boolean;
  type?: "banner" | "takeover";
  placement: AdPositions;
}) => {
  const { features } = getConfig();
  const globalStore = useGlobalStore();
  const isCollapsed = globalStore.collapsedAdIds.includes(id);
  const { article } = useContext(ArticleContext);
  const enhancedRouter = useEnhancedRouter();
  const [pre, setPre] = useState(true);
  const [adRule, setAdRule] = useState<AdRuleType | null>(null);
  const [takeoverTimedOut, setTakeoverTimedOut] = useState<boolean>(false);
  const [takeoverTimeoutId, setTakeoverTimeoutId] =
    useState<ReturnType<typeof setTimeout>>();
  const [isTakeoverVisible, setTakeoverVisible] = useState<boolean>(false);
  const [styles, setStyles] = useState<CSSProperties>({
    ...customStyle,
    width: "100%"
  });
  const [creativeId, setCreativeId] = useState<number>();
  const myRef = useRef<HTMLDivElement>(null);
  const onChangedCalledForTakeover = useRef(false);
  const [isViewed, setIsViewed] = useState(false);

  const takeoverOnFailure = useCallback(() => {
    setTakeoverVisible(false);
    document.body.classList.remove("no-scroll");
  }, []);

  const marketingConsent = useCookiePermission("CMP:marketing");
  const consentToAll = useConsentToAll();

  if (type === "takeover") {
    onAvailable = async (e, adRule) => {
      if (!takeoverTimedOut) {
        clearTimeout(takeoverTimeoutId);
        const apntag = await getApnTag();
        apntag.showTag(id);
        setTakeoverVisible(true);
        document.body.classList.add("no-scroll");
      }
    };
    onFailure = takeoverOnFailure;
  }

  const loadAd = useCallback(async () => {
    if (
      adRule &&
      myRef.current &&
      !isConfiguredAndLoaded.has(adRule.rules.position)
    ) {
      const adContainer: HTMLDivElement = myRef.current;
      isConfiguredAndLoaded.add(adRule.rules.position);
      const topicName = await getTopicName(enhancedRouter.topic);
      adContainer.innerHTML = "";

      const adFailure = (e?: AstAdObj) => {
        setPre(false);
        if (onFailure) {
          onFailure(e);
        }
      };

      const geoTracking =
        getGeoTrackingPermission(globalStore.permissions) && !!consentToAll;

      const { stylesFromXandr, creativeId, isNativeBanner } =
        await configureAndLoadAdPlacement({
          id,
          adRule,
          userContext: globalStore,
          article,
          topicName,
          adContainer,
          onFailure: adFailure,
          onLoaded,
          onAvailable,
          consentToAll,
          geoTracking
        });

      if (isNativeBanner) {
        setCreativeId(creativeId);
        setStyles({
          ...styles,
          ...stylesFromXandr
        });
      }
    }
  }, [
    id,
    adRule,
    article,
    globalStore,
    onFailure,
    onLoaded,
    onAvailable,
    enhancedRouter.topic,
    styles,
    consentToAll
  ]);

  const onChange = useCallback(
    async (isVisible: boolean) => {
      if (isViewed && myRef.current) {
        return;
      }

      if (isVisible && myRef.current) {
        setIsViewed(true);
        await loadAd();
      }
    },
    [isViewed, loadAd]
  );

  useEffect(() => {
    if (type === "takeover" && adRule && !forceShow(adRule)) {
      const timeoutId = setTimeout(() => {
        setTakeoverTimedOut(true);
      }, 2000);

      setTakeoverTimeoutId(timeoutId);

      return () => clearTimeout(timeoutId);
    }

    return () => {};
  }, [type, adRule]);

  useEffect(() => {
    if (marketingConsent === null) {
      return;
    }

    const config = {
      category: enhancedRouter.category || article?.category?.category_id,
      topics: enhancedRouter.topic
        ? [enhancedRouter.topic]
        : [
            article?.story?.topic_id || "",
            ...(article?.tags?.map(t => t.topic_id) || [])
          ].filter(a => a)
    };

    const rule = frequencyControlFilter(
      getAdRule(globalStore.adRules, config, placement)
    );

    setAdRule(rule);
    !rule && onFailure && onFailure(null); // custom hide
  }, [
    globalStore.adRules,
    article,
    className,
    enhancedRouter.category,
    enhancedRouter.topic,
    id,
    marketingConsent,
    onFailure,
    placement
  ]);

  useEffect(() => {
    if (isCollapsed && onFailure) {
      onFailure(null);
    }
  }, [isCollapsed, onFailure]);

  useEffect(() => {
    if (
      type === "takeover" &&
      myRef.current &&
      adRule &&
      !onChangedCalledForTakeover.current
    ) {
      onChangedCalledForTakeover.current = true;
      onChange(true);
    }
  }, [type, adRule, onChange]);

  if (marketingConsent === null && type !== "takeover") {
    return (
      <div className={`${className} banner--placeholder`}>
        <div className="banner--placeholder-inner"></div>
      </div>
    );
  }

  if (
    !adRule ||
    globalStore.subscriptionsInfo.isAdFree ||
    !features.some(feature => feature === "ADS_ENABLED")
  ) {
    return null;
  }

  const preBannerClassNames = getPreBanneClassNames(className);

  if (isCollapsed) {
    return null;
  }

  const takeOverVisibility = (visible: boolean) => {
    setTakeoverVisible(visible);
  };

  const label = adRule?.rules?.label ?? "Annons";

  if (type === "takeover") {
    return (
      <Takeover
        id={id}
        isTakeoverVisible={isTakeoverVisible}
        setTakeoverVisible={takeOverVisibility}
        myRef={myRef}
        className={className}
        preBannerClassNames={preBannerClassNames}
        label={label}
        creativeId={creativeId}
      />
    );
  }

  return (
    <>
      <VisibilitySensor
        onChange={onChange}
        scrollCheck={true}
        partialVisibility={true}
        offset={getOffset(placement)}
      >
        <>
          {children}
          {!pre || skipPre ? null : (
            <div
              style={{ width: styles.width }}
              className={`pre-banner ${preBannerClassNames}`}
            >
              <div className="pre-banner-left">{label}</div>
              <div className="pre-banner-right">
                <CogwheelButton creativeId={creativeId} />
              </div>
            </div>
          )}
          <div style={styles} ref={myRef} className={className} id={id}></div>
        </>
      </VisibilitySensor>
    </>
  );
};
