// Lasciate ogne speranza, voi ch'intrate
import type {
  AdInformation,
  ConfigAndLoadAdPlacement,
  AstAdObj
} from "@omni/ads";
import type { Article, AdRuleType } from "../../../../client";
import type { UserState } from "../../../../containers/GlobalStateContainer/userState/types";

import {
  getAdFormats,
  getAdId,
  getApnTag,
  getGeoTrackingPermission,
  getKeywords,
  getPPIDWithTagValues,
  isNativeAd,
  isNativeBanner,
  sizeToArray
} from "./functions";
import { isConfiguredAndLoaded } from "./constants";
import { loadAds } from "./bidding";

export const loadAdsWhenNavigate = async ({
  user,
  ads = [],
  article,
  marketingConsent,
  consentToAll
}: {
  user: UserState;
  ads: AdRuleType[] | [];
  article?: Article;
  advertisingConsent: boolean | null;
  marketingConsent?: boolean | null;
  consentToAll: boolean | null;
}) => {
  const isGeoTrackingAllowed =
    getGeoTrackingPermission(user.permissions) && !!consentToAll;

  const browser = window as any;
  const [
    apntag,
    adId,
    [ppIds, ppIdsTags],
    keywordsWithoutArticle,
    keywordsWithArticle
  ] = await Promise.all([
    getApnTag(),
    getAdId(),
    getPPIDWithTagValues(),
    getKeywords({
      user,
      geoLocationTracking: isGeoTrackingAllowed,
      article,
      consentToAll
    }),
    getKeywords({
      user,
      geoLocationTracking: isGeoTrackingAllowed,
      consentToAll
    })
  ]);

  if (!apntag?.loaded) {
    return;
  }

  const pageOptKeywords = article
    ? { ...keywordsWithArticle, ...ppIdsTags }
    : { ...keywordsWithoutArticle, ...ppIdsTags };

  apntag.setPageOpts({
    member: 9943,
    keywords: pageOptKeywords,
    user: {
      externalUid: adId,
      userIds: [{ type: "extendedIDs", eids: ppIds }]
    }
  });

  if (ads.length > 0) {
    try {
      ads
        .filter(ad => ad.type === "AppnexusBanner")
        .filter(ad => isConfiguredAndLoaded.has(ad.rules.position))
        .map(ad => {
          isConfiguredAndLoaded.delete(ad.rules.position);
          const constainerId = article
            ? `#${ad.rules.position}-${article.article_id}`
            : ad.rules.position;

          const adContainer = browser.document.querySelector(`${constainerId}`);
          const id: string | null = adContainer && adContainer?.id;

          if (!id) {
            return;
          }

          adContainer.innerHTML = "";

          const tag = {
            invCode: ad.appnexus_data.inventory_code,
            sizes: ad.appnexus_data.sizes.map(sizeToArray),
            targetId: id,
            keywords: {
              "aa-sch-supply_type": `${
                ad.appnexus_data.inventory_code.indexOf("wde") > -1
                  ? "web_desktop"
                  : "web_phone"
              }`,
              "se-generic-adformat": getAdFormats({
                adFormats: ad.appnexus_data.ad_formats,
                marketingConsent: marketingConsent
              })
            },
            enableSafeFrame: true,
            setSafeFrameConfig: {
              allowExpansionByPush: true,
              allowExpansionByOverlay: true
            }
          };

          apntag.defineTag(tag);
          loadAds([id]);
        });
    } catch (e) {
      console.error("Error appnexus onNavigate", e);
    }
  }
};

// Configure and load ad for specified adRule (placement)
export const configureAndLoadAdPlacement = async ({
  id,
  adRule,
  userContext,
  article,
  topicName,
  adContainer,
  consentToAll,
  onFailure,
  onLoaded,
  onAvailable,
  geoTracking
}: ConfigAndLoadAdPlacement): Promise<AdInformation> => {
  const [apntag, adId, [ppIds, ppIdsTags]] = await Promise.all([
    getApnTag(),
    getAdId(),
    getPPIDWithTagValues()
  ]);

  return new Promise(resolve => {
    const failureCallback = (ad: any) => {
      onFailure(ad);
      adContainer.classList.add("hide");
    };

    try {
      const tag = {
        invCode: adRule.appnexus_data.inventory_code,
        sizes: adRule.appnexus_data.sizes.map(sizeToArray),
        targetId: id,
        enableSafeFrame: true,
        setSafeFrameConfig: {
          allowExpansionByPush: true,
          allowExpansionByOverlay: true
        },
        allowedAds: adRule.appnexus_data.allowed_formats,
        keywords: {
          "se-generic-adformat": getAdFormats({
            adFormats: adRule.appnexus_data.ad_formats
          })
        }
      };

      apntag.anq.push(async () => {
        const keywords = await getKeywords({
          geoLocationTracking: geoTracking,
          user: userContext,
          topicName,
          article,
          consentToAll
        });
        apntag.setPageOpts({
          member: 9943,
          keywords: {
            ...keywords,
            ...ppIdsTags
          },
          user: {
            externalUid: adId,
            userIds: [{ type: "extendedIDs", eids: ppIds }]
          }
        });

        apntag.onEvent("adNoBid", id, failureCallback);
        apntag.onEvent("adRequestFailure", id, failureCallback);
        apntag.onEvent("adError", id, failureCallback);
        apntag.onEvent("adBadRequest", id, failureCallback);
        apntag.onEvent("adAvailable", id, e => {
          onAvailable && onAvailable(e, adRule);
        });
        apntag.onEvent("adLoaded", id, (e: AstAdObj) => {
          onLoaded && onLoaded(e, adRule);

          if (isNativeAd(e)) {
            apntag.fireImpressionTrackers(e);
          }

          if (isNativeBanner(e)) {
            const subContainer = document.querySelector(
              `#div_utif_${id}`
            ) as HTMLDivElement;

            const ad = e;

            const adWidth = ad?.width <= 5 ? "100%" : `${ad?.width}`;

            const adHeight = ad?.height <= 5 ? "100%" : `${ad?.height}`;

            if (subContainer) {
              subContainer.style.width = adWidth;
              const iframe = subContainer.querySelector("iframe");
              if (iframe) {
                iframe.style.width = adWidth;
                iframe.style.height = adHeight;
              }
            }

            resolve({
              stylesFromXandr: {
                width: adWidth,
                height: adHeight
              },
              creativeId: e.creativeId,
              isNativeBanner: true
            });
          } else {
            resolve({
              stylesFromXandr: { width: "100%" },
              isNativeBanner: false
            });
          }
        });

        apntag.defineTag(tag);
        loadAds([id]);
        if (!["takeoverDesktop", "takeoverMobile"].includes(id)) {
          apntag.showTag(id);
        }
      });
    } catch (e) {
      console.error("appnexus errored during apntag work", e);
      throw e;
    }
  });
};
