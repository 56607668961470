import { ApiClient } from "../../apiClient";

export function deleteBookmark(
  article_id: string,
  signal?: AbortSignal
): Promise<void> {
  const settingsClient = new ApiClient("DELETE", "settings");

  return settingsClient.send(
    `/bookmarks/${article_id}`,
    {
      mode: "cors",
      credentials: "include",
      signal
    },
    "empty"
  );
}
