import React from "react";

import type { LegacyRef } from "react";

import Back from "../../../assets/svg/back-secondary.svg";
import { getConfig } from "../../../config";
import { CogwheelButton } from "../../Modal/buttons/CoghwheelButton";

type TakeoverProps = {
  id: string;
  isTakeoverVisible: boolean;
  setTakeoverVisible: (visible: boolean) => void;
  myRef: LegacyRef<HTMLDivElement> | undefined;
  className: string;
  preBannerClassNames: string;
  label: string;
  creativeId?: number;
};

const Takeover: React.FC<TakeoverProps> = ({
  id,
  isTakeoverVisible,
  setTakeoverVisible,
  myRef,
  className,
  preBannerClassNames,
  label,
  creativeId
}) => {
  const { NEXT_PUBLIC_NEWSROOM } = getConfig();

  const closeTakeover = () => {
    setTakeoverVisible(false);
    document.body.classList.remove("no-scroll");
  };

  return (
    <div id="takeover" className={isTakeoverVisible ? "" : "hidden"}>
      <div id="takeover-header">
        <i className="icon icon--app" />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <button id="takeover-button" onClick={closeTakeover}>
          Till {NEXT_PUBLIC_NEWSROOM === "omni" ? "Omni" : "Omni Ekonomi"}
          <Back />
        </button>
      </div>
      <div id="takeover-body">
        <div className={`pre-banner ${preBannerClassNames}`}>
          <div className="pre-banner-left">{label}</div>
          <div className="pre-banner-right">
            <CogwheelButton creativeId={creativeId} />
          </div>
        </div>
        <div ref={myRef} className={className} id={id} />
      </div>
    </div>
  );
};

export { Takeover };
