import React, { useCallback, useRef, useState } from "react";

import type { AstAdObj } from "@omni/ads";

import { CogwheelButton } from "../../Modal/buttons/CoghwheelButton";
import { setCustomFullscreenStyles } from "../utils/ads/functions";
import { Ad } from "..";

export const FullscreenBanner = () => {
  const [display, setDisplay] = useState<"" | "none">("");
  const [creativeId, setCreativeId] = useState<number>();

  const onFailure = useCallback(() => {
    setDisplay("none");
  }, []);

  const adContainer = useRef<HTMLDivElement>(null);

  const onLoaded = useCallback((response: AstAdObj) => {
    setCreativeId(response.creativeId);

    const subContainer = document.querySelector(
      `#div_utif_${response.targetId}`
    ) as HTMLDivElement;
    subContainer.style.width = "100vw";
    subContainer.style.height = "100%";
  }, []);

  const onAvailable = useCallback((response: AstAdObj) => {
    if (
      response?.banner &&
      response.banner.height === 1080 &&
      response.banner.width === 1920
    ) {
      setCustomFullscreenStyles(response.targetId, "xl");
    }
  }, []);

  return (
    <div
      style={{ background: "transparent", display }}
      key="fullscreen-ad"
      id="banner--fullscreen"
      ref={adContainer}
      className={`group group--banner banner--fullscreen hidden-sm`}
    >
      <Ad
        id="fullscreen"
        className="banner--fullscreen-container"
        placement="fullscreen"
        onFailure={onFailure}
        onLoaded={onLoaded}
        onAvailable={onAvailable}
        skipPre={true}
      >
        <div className={`pre-banner banner--fullscreen-label`}>
          <div className="pre-banner-left-bigger">Annons</div>
          <div className="pre-banner-right">
            <CogwheelButton creativeId={creativeId} />
          </div>
        </div>
      </Ad>
    </div>
  );
};
