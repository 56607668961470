import React, { useContext, useCallback, useState } from "react";

import type { AstAdObj } from "@omni/ads";

import { ArticleContext } from "../../../contexts/ArticleContext";
import { Ad } from "..";
import { getLayoutName } from "../utils/ads/functions";

export function NativeArticleAd() {
  const { article } = useContext(ArticleContext);
  const [customStyle, setCustomStyle] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const onLoaded = useCallback((response: AstAdObj) => {
    const style = {
      width: "100%",
      height: `${response.height.toString()}px`,
      display: "block"
    };
    const { targetId } = response;
    const subContainer = document.querySelector(
      `#div_utif_${targetId}`
    ) as HTMLDivElement;

    if (subContainer) {
      subContainer.style.width = style.width;
    }
    setIsLoaded(true);
    setCustomStyle(style);
  }, []);

  const onFailure = useCallback(() => {
    setIsLoaded(false);
  }, []);

  if (!article?.sponsor) {
    return null;
  }

  const layout = getLayoutName();
  return (
    <Ad
      id={`afterShareButtons${
        layout ? `${layout[0].toUpperCase()}${layout.substring(1)}` : ""
      }-${article.article_id}`}
      className="banner banner--nativeside"
      placement={`${
        layout === "desktop"
          ? "afterShareButtonsDesktop"
          : "afterShareButtonsMobile"
      }`}
      onLoaded={onLoaded}
      customStyle={customStyle}
      skipPre={!isLoaded}
      onFailure={onFailure}
    />
  );
}
