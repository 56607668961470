import type { RelevantYieldEvents } from "@omni/ads";

import { getApnTag, getBiddingSdk, getUserIds } from "./functions";
import { getConfig } from "../../../../config";

const { NEXT_PUBLIC_PREBID_CONFIG_ID } = getConfig();

const loadAds = async (params: string[]) => {
  const apntag = await getApnTag();
  const biddingSdk = await getBiddingSdk();
  const userIds = await getUserIds();

  let cancel = false; // if true => we've timed out and and "emergency-call" to apntag.loadTags() has already been done

  const events = {
    relevantCb: {
      timeout: 1000,
      done: false
    }, // Wait up to 1 second for the Relevant script
    auctionCb: {
      timeout: 2000,
      done: false
    } // Wait up to 2 seconds for the auction to complete
  };

  const failsafeOnTimeout = (ev: keyof RelevantYieldEvents) =>
    setTimeout(() => {
      if (!events[ev].done) {
        console.error(
          `Doing failsafe loadTags() call after waiting too long for '${ev}'`
        );
        cancel = true;
        apntag.loadTags();
      }
    }, events[ev].timeout);

  failsafeOnTimeout("relevantCb");

  biddingSdk.cmd.push(function () {
    if (cancel) {
      // We were too slow -  do nothing
      return;
    }
    events.relevantCb.done = true;
    // Handle special-case of 0 placement selected in all prebid configurations, as the Relevant-script
    // will then be changed to an "analytics-only" version without HBM.
    if (!biddingSdk.loadPrebid) {
      console.error(`Calling loadTags() without Relevant`);
      apntag.anq = apntag.anq || [];
      apntag.anq.push(() => apntag.loadTags());
      return;
    }
    failsafeOnTimeout("auctionCb");
    biddingSdk.addPrebidConfig({
      consentManagement: {
        gdpr: {
          cmpApi: "iab",
          timeout: 8000
        }
      },
      enableSendAllBids: false, // Only send key-values for *winning* bids (HB_PB etc)
      userSync: {
        userIds
      }
    });
    biddingSdk.loadPrebid({
      configId: `${NEXT_PUBLIC_PREBID_CONFIG_ID}`,
      manageAdserver: false,
      loadUnknownSlots: true, // Load placements/tags not added in Yield
      noSlotReload: true, // Don't reload placements that has already been loaded
      noGpt: true, // Don't load gpt.js as it's done above
      apntagCalls: {
        showTag: () => {
          /** Skip, we're doing this ourselves */
        },
        refresh: () => {
          /** Skip, we're doing this ourselves */
        },
        loadTags: (args: string[]) => {
          if (!cancel) {
            events.auctionCb.done = true;
            apntag.loadTags(args);
          } // else => We were too slow - do nothing
        }
      },
      ...params
    });
  });
};

export { loadAds };
